import React from "react";
import theme from "theme";
import { Theme, Link, Image, Strong, Text, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				ThePharaohs eSports
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Box
			min-width="100px"
			min-height="100px"
			display="flex"
			width="100% border-box"
			height="100vh"
			justify-content="center"
			background="#111111"
			flex-direction="column"
			align-content="center"
			flex-wrap="wrap"
			align-items="center"
		>
			<Image src="https://uploads.quarkly.io/646e9d326efcea001ae0202c/images/icoArtboard%201@0.25x.png?v=2023-05-24T23:34:15.787Z" display="block" width="320px" height="320px" />
			<Text
				margin="0px 0px 0px 0px"
				display="block"
				text-align="center"
				font="30px sans-serif"
				color="#ffffff"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Coming Soon
				</Strong>
			</Text>
			<Section background="#111111" padding="60px 0" sm-padding="40px 0">
				<SocialMedia facebook="https://www.facebook.com/ePharaohs/" youtube="https://www.youtube.com/@ThePharaohs_" instagram="https://www.instagram.com/pharaohs.esports/" icon-size="28px">
					<Override
						slot="link"
						border-radius="50%"
						color="--grey"
						hover-color="--light"
						background="transparent"
						hover-background="transparent"
						margin="0 8px"
					/>
				</SocialMedia>
			</Section>
		</Box>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"646e9d326efcea001ae0202a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});