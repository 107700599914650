export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"646e9d326efcea001ae02031",
				"646e9d326efcea001ae02034"
			]
		},
		"646e9d326efcea001ae02031": {
			"id": "646e9d326efcea001ae02031",
			"name": "404",
			"pageUrl": "404"
		},
		"646e9d326efcea001ae02034": {
			"id": "646e9d326efcea001ae02034",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"title": "ThePharaohs eSports"
		}
	}
}